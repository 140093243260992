<template>
  <div class="watch-page container-fluid">
    <div v-if="!matchLoaded" class="match-loading">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 col-8">
            <div class="content">
              <h3>carregando partida</h3>
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="matchLoaded && !isMatchReady" class="match-parsing">
      <div class="container">
        <div class="row">
          <div class="col-1 col-md-2 text-right">
            <b-spinner
              class="mt-4 mb-3"
              variant="light"
              label="Processando"
            ></b-spinner>
          </div>
          <div class="col-11 col-md-10">
            <div class="mt-3 mb-3 text-light">
              <h4>{{ $t("MATCHES.parsing_alert.title") }}</h4>
              <p>{{ $t("MATCHES.parsing_alert.subtitle") }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-1 col-md-2"></div>
          <div class="col-11 col-md-10 text-light">
            <h4 class="mt-4">aproveite e convide seus amigos</h4>
            <p>Compartilhe o link abaixo</p>

            <b-input
              id="tooltip-clipboard"
              v-clipboard:success="clipboardSuccessHandler"
              v-clipboard="
                () =>
                  `https://fightback.club/?referral_code=${
                    this.currentUser && this.currentUser.id
                      ? this.currentUser.id
                      : ''
                  }`
              "
              type="text"
              :value="`https://fightback.club/?referral_code=${
                this.currentUser && this.currentUser.id
                  ? this.currentUser.id
                  : ''
              }`"
            >
            </b-input>

            <b-tooltip
              :show="showClipboardSuccess"
              target="tooltip-clipboard"
              placement="top"
            >
              <span v-if="!showClipboardSuccess">
                clique para copiar o link
              </span>
              <span v-else> link copiado </span>
            </b-tooltip>

            <a
              b-tooltip.hover
              title="compartilhar no twitter"
              class="btn btn-dark"
              target="_blank"
              :href="`http://twitter.com/share?text=Reveja+e+analise+partidas+de+CSGO+direto+no+navegador+sem+baixar+nada&url=https://fightback.club/?referral_code=${
                this.currentUser && this.currentUser.id
                  ? this.currentUser.id
                  : ''
              }`"
            >
              <i class="fab fa-twitter-square"></i>
            </a>

            <a
              b-tooltip.hover
              title="compartilhar no facebook"
              class="btn btn-dark"
              target="_blank"
              :href="`http://www.facebook.com/sharer.php?p[title]=Reveja+e+analise+partidas+de+CSGO+direto+no+navegador+sem+baixar+nada&u=https://fightback.club/?referral_code=${
                this.currentUser && this.currentUser.id
                  ? this.currentUser.id
                  : ''
              }`"
            >
              <i class="fab fa-facebook-square"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters" v-if="false">
      <div class="col-12 col-md-12">
        <div
          class="match-header"
          v-if="isMatchReady && false"
          :class="match.map"
        >
          <div class="row no-gutters">
            <div class="col-12 col-md-9">
              <div class="header-content">
                <div class="team-column" v-if="match.team_a && match.team_b">
                  <div v-if="match.team_a" class="team">
                    {{ match.team_a.name }}
                  </div>
                  x
                  <div v-if="match.team_b" class="team">
                    {{ match.team_b.name }}
                  </div>
                </div>
                <p class="score">
                  {{ match.team_a_score }}:{{ match.team_b_score }}
                </p>
                <p class="mt-0" v-html="match.map"></p>
                <div class="d-block">
                  <b-btn
                    v-b-modal.modalEditMatch
                    v-if="isLoggedIn && match.has_admin_access"
                    block
                  >
                    <i class="fas fa-edit"></i>
                    {{ $t("MATCHES.actions.edit_match") }}
                  </b-btn>

                  <b-btn
                    @click="destroyMatch"
                    v-if="isLoggedIn && isAdmin"
                    block
                  >
                    <i class="fas fa-trash"></i>
                    {{ $t("MATCHES.actions.delete_match") }}
                  </b-btn>

                  <p v-html="match.name || $t('UTILITY.unnamed')"></p>
                  <p v-html="match.description"></p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <div class="header-sidebar">
                <b-btn
                  v-b-modal.modalCollection
                  v-if="isLoggedIn && isPremium && false"
                  block
                >
                  <i class="fas fa-folder-plus"></i>
                  {{ $t("MATCHES.actions.add_to_collection") }}
                </b-btn>

                <b-btn
                  v-b-modal.modalShare
                  v-if="
                    isLoggedIn && match.has_admin_access && isPremium && false
                  "
                  block
                >
                  <i class="fas fa-share"></i>
                  {{ $t("MATCHES.actions.share_match") }}
                </b-btn>

                <a
                  :href="match.demofile_url"
                  target="_blank"
                  class="btn btn-secondary btn-block"
                >
                  <i class="fas fa-download"></i>
                  {{ $t("MATCHES.actions.download_match") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="no-gutters row" v-if="matchLoaded && isMatchReady">
      <div
        :class="[
          sidebarHidden || true ? 'col-md-12 col-12' : 'col-md-8 col-12',
          'watch-player',
        ]"
      >
        <div :class="[sidebarHidden || true ? '' : '']">
          <div
            class="playerDiv"
            v-html="player_embed_html"
            v-if="player_embed_html"
          ></div>
        </div>
      </div>

      <div :class="[sidebarHidden || true ? 'col-md-12' : 'd-none']">
        <RoundsBar
          v-if="match && match.match_rounds && round"
          :rounds="match.match_rounds"
          @loadFullRound="loadFullRound"
          :round="round"
        ></RoundsBar>
      </div>
    </div>

    <div v-if="isMatchReady && matchLoaded">
      <div :class="[sidebarHidden || true ? 'd-none' : '']">
        <RoundsBar
          v-if="match && match.match_rounds && round"
          :rounds="match.match_rounds"
          @loadFullRound="loadFullRound"
          :round="round"
        ></RoundsBar>
      </div>

      <b-sidebar
        id="sidebar-comments"
        class="sidebar-comments"
        title="Comentários"
        right
        shadow
        text-variant="light"
        backdrop
        backdrop-variant="dark"
        width="40em"
      >
        <div :class="['watch-sidebar']">
          <div :class="['']">
            <div class="content">
              
              <div class="round-info">
                <h3>{{ $t('UTILITY.round') }} {{round.round_number}}</h3>
                <p>
                  <div
                    class="team-column"
                    v-if="round && round.match && round.match.team_a && round.match.team_b"
                  >
                    <span v-if="round.match.team_a" class="team">{{round.match.team_a.name}}</span>x
                    <span v-if="round.match.team_b" class="team">{{round.match.team_b.name}}</span>
                  </div>
                  <!-- <p v-else v-html="round.match.name || $t('UTILITY.unnamed')"></p> -->
                </p>

                <div class="round-details">
                  {{ $t('UTILITY.winner') }}
                  {{ friendlyWinner(round.winner) }} - {{ friendlyReason(round.reason) }}
                </div>

                <div class="round-badges">
                  <!-- <b-badge size="sm" variant="info">{{round.match.map}}</b-badge> -->
                  <b-badge size="sm" variant="secondary" v-if="round.round_type && false">
                    {{ round.round_type.replace('round_', '') }}
                    <span
                      v-if="round.side_trouble && ['round_force_buy', 'round_semi_eco', 'round_eco'].includes(round.round_type)"
                    >{{ round.side_trouble.replace('trouble_', '') }}</span>
                  </b-badge>

                  <h2 v-if="friendlyRoundType">
                    {{ friendlyRoundType }}
                  </h2>
                </div>

                <div class="round-stats">
                  <!-- <p class="stat" v-if="round.pageviews_count" v-b-tooltip.hover title="visualizações">
                    <i class="far fa-eye"></i>
                    {{round.pageviews_count}}
                  </p> -->
                  <p class="stat" v-if="round.comments_count" v-b-tooltip.hover title="comentários">
                    <i class="fas fa-comments"></i>
                    {{round.comments_count}}
                  </p>

                  <!-- <br>
                  <br> -->
                  <!-- <b-btn
                    v-b-tooltip.hover
                    :title="$t('ROUND_SINGLE.report_round_title')"
                    v-b-modal.modalReport
                    variant="light"
                    size="sm"
                    v-if="isLoggedIn"
                  >
                    {{ $t('ROUND_SINGLE.report_round') }}
                  </b-btn> -->
                </div>
              </div>

              <div class="comments-section">
                <div class="section-title">
                  <h5>
                    {{ $t('ROUND_SINGLE.comments.title') }}
                    <span v-if="round.comments_count">({{round.comments_count}})</span>
                  </h5>
                </div>

                <div
                  v-if="round.comments.length == 0"
                  class="text-muted text-center mt-4 mb-4"
                >
                  {{ $t('ROUND_SINGLE.comments.no_comments') }}
                </div>
                <div v-else>
                  <div class="comments-container">
                    <Comment :comment="comment" v-for="comment in round.comments" :key="comment.id"></Comment>
                  </div>
                </div>

                <div v-if="isLoggedIn">
                  <div class="new-comment">
                    <form @submit.stop.prevent="postComment">
                      <div class="comment-card post-comment">
                        <div class="user-avatar">
                          <router-link :to="{ name: 'UserSingle', params: { id: currentUser.id }}">
                            <img :src="currentUser.avatar.url">
                          </router-link>
                        </div>
                        <div class="comment">
                          <div class="comment-body">
                            <b-form-textarea
                              id="textarea"
                              v-model="commentText"
                              :placeholder="$t('ROUND_SINGLE.comments.comment_placeholder')"
                            ></b-form-textarea>
                            <b-btn type="submit" variant="secondary">
                              {{ $t('ROUND_SINGLE.comments.submit') }}
                            </b-btn>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div v-else class="text-muted text-center">
                  {{ $t('ROUND_SINGLE.comments.logged_out_warning') }}
                </div>
              </div>
            
            </div>









          </div>
        </div>
      </b-sidebar>

      <b-sidebar
        id="sidebar-scoreboard"
        class="sidebar-scoreboard"
        title="Scoreboard"
        right
        shadow
        text-variant="light"
        backdrop
        backdrop-variant="dark"
        width="40em"
      >
        <div class="px-3 py-2">
          <div class="container">
            <h3>{{ $t("UTILITY.round") }} {{ round.round_number }}</h3>
            <!-- <router-link :to="{ name: 'MatchDetails', params: { id: $route.params.id} }">ver estatisticas</router-link> -->

            <ScoreboardTable
              teamClass="team1"
              :team="match.team_a"
              :score="round.team_a_score"
              :stats="round.player_stats.filter((x) => x.teamNumber == 2)"
              v-if="round && round.player_stats"
            ></ScoreboardTable>
            <ScoreboardTable
              teamClass="team2"
              :team="match.team_b"
              :score="round.team_b_score"
              :stats="round.player_stats.filter((x) => x.teamNumber == 3)"
              v-if="round && round.player_stats"
            ></ScoreboardTable>
          </div>
        </div>
      </b-sidebar>

      <div class="match-actions-bar">
        <div class="container">
          <div class="row">

            <div class="col-12 col-md-6">
              <div class="round-info text-light" v-if="round && round.reason">
                <h3>{{ $t('UTILITY.round') }} {{round.round_number}}</h3>
                <p>
                  <div
                    class="team-column"
                    v-if="round && round.match && round.match.team_a && round.match.team_b"
                  >
                    <span v-if="round.match.team_a" class="team">{{round.match.team_a.name}}</span>x
                    <span v-if="round.match.team_b" class="team">{{round.match.team_b.name}}</span>
                  </div>
                  <!-- <p v-else v-html="round.match.name || $t('UTILITY.unnamed')"></p> -->
                </p>

                <div class="round-details">
                  {{ $t('UTILITY.winner') }}
                  {{ friendlyWinner(round.winner) }} - {{ friendlyReason(round.reason) }}
                </div>

                <div class="round-badges">
                  <h2 v-if="friendlyRoundType">
                    {{ friendlyRoundType }}
                  </h2>
                </div>

                <div class="round-stats">
                  <p class="stat" v-if="round.comments_count" v-b-tooltip.hover title="comentários">
                    <i class="fas fa-comments"></i>
                    {{round.comments_count}}
                  </p>

                  <!-- <br>
                  <br> -->
                  <!-- <b-btn
                    v-b-tooltip.hover
                    :title="$t('ROUND_SINGLE.report_round_title')"
                    v-b-modal.modalReport
                    variant="light"
                    size="sm"
                    v-if="isLoggedIn"
                  >
                    {{ $t('ROUND_SINGLE.report_round') }}
                  </b-btn> -->
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 justify-content-end align-items-center d-flex">
              <b-button variant="outline-light" v-b-toggle.sidebar-scoreboard
                >Tab/Score desse round</b-button
              >

              <b-button class="ml-2" variant="outline-light" v-b-toggle.sidebar-comments
                >Comentários</b-button
              >
            </div>


          </div>
        </div>
      </div>

      <div class="match-details-bar">
        <div class="match-header" v-if="isMatchReady" :class="match.map">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="header-content">
                  <div class="team-column" v-if="match.team_a && match.team_b">
                    <div v-if="match.team_a" class="team">
                      {{ match.team_a.name }}
                    </div>
                    x
                    <div v-if="match.team_b" class="team">
                      {{ match.team_b.name }}
                    </div>
                  </div>
                  <p class="score">
                    {{ match.team_a_score }}:{{ match.team_b_score }}
                  </p>
                  <p class="mt-0" v-html="match.map"></p>
                  <p v-html="match.name || $t('UTILITY.unnamed')"></p>
                  <p v-html="match.description"></p>
                </div>
              </div>

              <div class="col-12 col-md-3">
                <div class="header-sidebar">
                  <b-btn
                    v-b-modal.modalEditMatch
                    v-if="isLoggedIn && match.has_admin_access"
                    block
                  >
                    <i class="fas fa-edit"></i>
                    {{ $t("MATCHES.actions.edit_match") }}
                  </b-btn>

                  <b-btn
                    @click="destroyMatch"
                    v-if="isLoggedIn && isAdmin"
                    block
                  >
                    <i class="fas fa-trash"></i>
                    {{ $t("MATCHES.actions.delete_match") }}
                  </b-btn>

                  <b-btn
                    v-b-modal.modalCollection
                    v-if="isLoggedIn && isPremium"
                    block
                  >
                    <i class="fas fa-folder-plus"></i>
                    {{ $t("MATCHES.actions.add_to_collection") }}
                  </b-btn>

                  <b-btn
                    v-b-modal.modalShare
                    v-if="isLoggedIn && match.has_admin_access && isPremium"
                    block
                  >
                    <i class="fas fa-share"></i>
                    {{ $t("MATCHES.actions.share_match") }}
                  </b-btn>

                  <a
                    :href="match.demofile_url"
                    target="_blank"
                    class="btn btn-secondary btn-block"
                  >
                    <i class="fas fa-download"></i>
                    {{ $t("MATCHES.actions.download_match") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-4">
        <h2>Visão Geral</h2>

        <h5>Scoreboard Final</h5>

        <ScoreboardTable
          teamClass="team1"
          :team="match.team_a"
          :score="match.team_a_score"
          :stats="match.scoreboard.players.filter((x) => x.teamNumber == 2)"
          v-if="round && match.scoreboard.players"
        ></ScoreboardTable>
        <ScoreboardTable
          teamClass="team2"
          :team="match.team_b"
          :score="match.team_b_score"
          :stats="match.scoreboard.players.filter((x) => x.teamNumber == 3)"
          v-if="round && match.scoreboard.players"
        ></ScoreboardTable>
      </div>
    </div>

    <!-- modals -->
    <b-modal
      id="modalShare"
      ref="modalShare"
      :title="$t('MATCHES.share_modal.title')"
      :hide-footer="true"
    >
      <form @submit.stop.prevent="handleShareSubmit">
        <b-form-input
          type="text"
          :placeholder="$t('MATCHES.share_modal.email_placeholder')"
          v-model="share.email"
        ></b-form-input>

        <b-btn type="submit" variant="primary">
          {{ $t("MATCHES.share_modal.submit") }}
        </b-btn>
      </form>
    </b-modal>

    <b-modal
      id="modalCollection"
      ref="modalCollection"
      :hide-footer="true"
      :hide-header="true"
    >
      <CollectionModal
        :favorable_id="match.id"
        favorable_type="Match"
      ></CollectionModal>
    </b-modal>

    <b-modal
      id="modalTags"
      ref="modalTags"
      :title="$t('MATCHES.tags_modal.title')"
      :hide-footer="true"
    >
      <form @submit.stop.prevent="handleTagsSubmit">
        <div class="intro"></div>

        <vue-tags-input
          v-model="tag_list"
          :tags="tags"
          @tags-changed="(newTags) => (tags = newTags)"
        />

        <div class="tag-list">
          <h6>{{ $t("MATCHES.tags_modal.common_tags") }}</h6>
          <b-badge
            variant="light"
            @click="addTag(tag.name)"
            v-for="tag in commonTags"
            :key="tag.id"
            >{{ tag.name }}</b-badge
          >
        </div>

        <b-btn type="submit" variant="primary">
          {{ $t("MATCHES.tags_modal.submit") }}
        </b-btn>
      </form>
    </b-modal>

    <b-modal
      id="modalEditMatch"
      ref="modalEditMatch"
      :title="$t('MATCHES.edit_modal.title')"
      :hide-footer="true"
    >
      <form @submit.stop.prevent="handleEditSubmit">
        <b-form-input
          type="text"
          :placeholder="$t('MATCHES.edit_modal.name_placeholder')"
          v-model="match.name"
        ></b-form-input>

        <b-form-input
          type="text"
          placeholder="descricao"
          v-model="match.description"
        ></b-form-input>

        <div v-if="currentUser.role == 'role_user_admin'">
          <b-form-input
            type="text"
            :placeholder="$t('MATCHES.edit_modal.unique_code_placeholder')"
            v-model="match.unique_code"
          ></b-form-input>

          <b-form-select
            id="teamA"
            :placeholder="$t('UTILITY.team_a')"
            :options="teams"
            value-field="id"
            text-field="name"
            required
            v-model="match.team_a_id"
          ></b-form-select>

          <b-form-select
            id="teamB"
            :placeholder="$t('UTILITY.team_b')"
            :options="teams"
            value-field="id"
            text-field="name"
            required
            v-model="match.team_b_id"
          ></b-form-select>
          <b-form-checkbox v-model="match.is_public" name="check-button" button>
            {{ $t("MATCHES.edit_modal.is_public_label") }}
          </b-form-checkbox>
        </div>

        <b-btn type="submit" variant="primary">
          {{ $t("MATCHES.edit_modal.submit") }}
        </b-btn>
      </form>
    </b-modal>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import CollectionModal from "@/components/CollectionModal";
import LineChart from "@/components/Chart.vue";
import RoundsBar from "@/components/RoundsBar.vue";
import ScoreboardTable from "@/components/ScoreboardTable.vue";
import Comment from "@/components/Comment.vue";

export default {
  name: "MatchSingle",
  computed: {
    isPremium: function () {
      return (
        this.currentUser &&
        this.currentUser.subscription_package &&
        this.currentUser.subscription_package.package != "free"
      );
    },
    isMatchReady: function () {
      return (
        this.match &&
        !["demofile_state_parsing", "demofile_state_waiting"].includes(
          this.match.demofile_state
        )
      );
    },
    friendlyRoundType: function () {
      if (this.round && this.round.side_trouble && this.round.round_type) {
        try {
          if (
            this.round.side_trouble == "trouble_none" &&
            this.round.round_type == "round_normal"
          )
            return "Compra";
          else {
            let troubleSide = this.round.side_trouble.replace("trouble_", "");
            switch (troubleSide) {
              case "terrorists":
                troubleSide = "TR";
                break;
              case "cts":
                troubleSide = "CT";
                break;
              default:
                troubleSide = "";
                break;
            }

            let friendlyType = "";
            switch (this.round.round_type) {
              case "round_normal":
                friendlyType = "Compra";
                break;
              case "round_force_buy":
                friendlyType = "Forçado";
                break;
              case "round_semi_eco":
                friendlyType = "Semi eco";
                break;
              case "round_eco":
                friendlyType = "Eco";
                break;

              case "round_pistol":
                friendlyType = "Pistol";
                break;
            }
            return `${friendlyType} ${troubleSide}`;
          }
        } catch (_) {
          return false;
        }
      } else false;
    },
  },
  data() {
    return {
      matchLoaded: false,
      round_on_url: "",
      match: {},
      share: { email: "" },
      collections: [],
      teams: [],
      tag_list: "",
      tags: [],
      commonTags: [],

      player_embed_html: "",

      current_round: { id: 0, round_number: 0 },
      round: {
        round_file_url: "",
        team_a: {},
        team_b: {},
        comments: [],
        related_rounds: [],
      },
      commentText: "",
      reportText: "",

      sidebarHidden: false,

      showClipboardSuccess: false,

      loaded: false,
      chartdata: null,
      options: {},

      round_to_tag: "",
      team_a_score: [],
      team_b_score: [],
      team_a_money: [],
      team_b_money: [],
    };
  },
  methods: {
    initialize() {
      var self = this;

      this.$http
        .get("matches/" + this.$route.params.id + ".json")
        .then((response) => {
          self.match = response.data;

          self.team_a_score = response.data.economy.round.map(
            (r) => r.team_a_score
          );
          self.team_b_score = response.data.economy.round.map(
            (r) => r.team_b_score
          );

          self.team_a_money = response.data.economy.round.map(
            (r) => r.team_a.cashSpendThisRound / 1000
          );
          self.team_b_money = response.data.economy.round.map(
            (r) => r.team_b.cashSpendThisRound / 1000
          );

          self.route_on_url = self.$route.params.round;
          if (typeof self.route_on_url == "undefined") {
            self.updateRoundRoute(response.data.first_round_embed.round_number);
            self.current_round.round_number =
              response.data.first_round_embed.round_number;
          } else {
            self.current_round.round_number = self.route_on_url.replace(
              "round_",
              ""
            );
          }

          self.matchLoaded = true;
          if (this.isMatchReady)
            self.loadRoundByNumber(
              self.current_round.round_number,
              self.match.id
            );
        });

      this.$http.get("teams.json").then((response) => {
        this.teams = response.data;
        this.teams.push({ id: null, name: "em branco" });
      });

      if (this.isLoggedIn)
        this.$http
          .get("collections.json")
          .then((response) => (this.collections = response.data));

      this.$http
        .get("common_tags.json")
        .then((response) => (this.commonTags = response.data));
    },

    toggleSidebar() {
      this.sidebarHidden = !this.sidebarHidden;
    },

    clipboardSuccessHandler({ value, event }) {
      this.showClipboardSuccess = true;
    },

    handleShareSubmit() {
      this.$http
        .post(`matches/${this.$route.params.id}/share.json`, this.share)
        .then((response) => {
          this.$notify({
            group: "all",
            text: "Convite enviado",
            type: "success",
            duration: -1000,
          });
          this.$refs.modalShare.hide();
        })
        .catch((error) => {
          // console.log(error);
        });
    },

    destroyMatch() {
      if (
        prompt("AVISO: Tem certeza que deseja apagar?\nDigite sim") == "sim"
      ) {
        this.$http
          .delete(`matches/${this.$route.params.id}.json`)
          .then((response) => {
            this.$notify({
              group: "all",
              text: "Partida apagada com sucesso",
              type: "success",
              duration: -1000,
            });
            this.$router.push("/");
          });
      }
    },

    handleTagsSubmit() {
      this.$http
        .patch(`match_rounds/${this.round_to_tag}.json`, {
          match: {
            tag_list: this.tags
              .map((t) => {
                return t.text;
              })
              .join(","),
          },
        })
        .then((response) => {
          this.initialize();
          this.$refs.modalTags.hide();
        });
    },

    handleEditSubmit() {
      this.$http
        .patch(`matches/${this.$route.params.id}.json`, {
          match: {
            name: this.match.name,
            description: this.match.description,
            team_a_id: this.match.team_a_id,
            team_b_id: this.match.team_b_id,
            unique_code: this.match.unique_code,
            is_public: this.match.is_public,
          },
        })
        .then((response) => {
          this.initialize();
          this.$refs.modalEditMatch.hide();
        });
    },

    addTag(tagName) {
      this.tags.push({ text: tagName });
    },

    loadRound(round_id) {
      this.$http.get(`match_rounds/${round_id}.json`).then((response) => {
        this.round = response.data;
        // this.player_embed_html = response.data.round_embed.html;
      });

      // this.$route.params.round
    },

    loadFullRound(round_id) {
      this.$http.get(`match_rounds/${round_id}.json`).then((response) => {
        this.round = response.data;
        this.player_embed_html = response.data.round_embed.html;
        this.updateRoundRoute(this.round.round_number);
      });
    },

    loadRoundByNumber(round_number, match_id) {
      this.$http
        .get(
          `match_rounds/find_by_number.json?round_number=${round_number}&match_id=${match_id}`
        )
        .then((response) => {
          this.round = response.data;
          this.player_embed_html = response.data.round_embed.html;
        });

      // this.$route.params.round
    },

    handleMessage(event) {
      var self = this;
      if (
        event.data.hasOwnProperty("action") &&
        event.data.action == "roundLoaded"
      ) {
        var pieces = event.data.roundUrl.split("/");
        var roundUrl = `${pieces[pieces.length - 2]}/${
          pieces[pieces.length - 1]
        }`;
        this.$http
          .get(`match_rounds/find_by_embed_url.json?url=${roundUrl}`)
          .then((response) => {
            self.loadRound(response.data.id);
            self.updateRoundRoute(response.data.round_number);
          });
      }
    },

    handleSubmitReport() {
      this.$http
        .post(`match_rounds/${this.$route.params.id}/report.json`, {
          reportText: this.reportText,
        })
        .then((response) => {
          this.$notify({
            group: "all",
            text:
              "Estamos verificando esse round. Muito obrigado por reportar!",
            type: "success",
            duration: -1000,
          });
          this.reportText = "";
          this.$refs.modalReport.hide();
        });
    },

    postComment() {
      var self = this;
      this.$http
        .post(`comments.json`, {
          comment: {
            commentable_id: self.round.id,
            commentable_type: "MatchRound",
            body: self.commentText,
          },
        })
        .then((response) => {
          self.commentText = "";
          self.loadRound(self.round.id);
          // this.initialize();
          // FIXME TODO atualizar apenas o round
        });
    },

    updateRoundRoute(round_number) {
      this.$route.params.round = round_number;
      let pieces = this.$route.path.split("/");
      // console.log(this.$route.path);
      if (pieces[pieces.length - 1].match(/round_/)) {
        history.pushState(
          {},
          null,
          this.$route.path.replace(
            pieces[pieces.length - 1],
            `round_${round_number}`
          )
        );
      } else {
        history.pushState(
          {},
          null,
          `${this.$route.path}/round_${round_number}`
        );
      }
      // /${this.$route.params.id}
    },
  },
  mounted() {
    this.initialize();
    setTimeout(() => {
      window.addEventListener("message", this.handleMessage);
    }, 500);
  },
  watch: {
    "$route.params.id": function (id) {
      this.initialize();
    },
    "$route.params.round": function (round) {
      // this.loadRound(round);
    },
  },
  components: {
    VueTagsInput,
    CollectionModal,
    LineChart,
    RoundsBar,
    ScoreboardTable,
    Comment,
  },
};
// Header
</script>

<style lang="sass">
#sidebar-comments
  background: rgba(0, 0, 0, .89) !important
#sidebar-scoreboard
  background: rgba(0, 0, 0, .89) !important
  table
    color: white
    td
      border-top: 0
.watch-page .responsive-fightback-embed iframe
  height: 70vh
</style>

<style lang="sass" scoped>
.match-actions-bar
  background: $primary
  padding: 1rem 0
.match-loading
  background: #121832
  .content
    margin: 40px 0
    text-align: center
    color: white
.match-parsing
  background: #121832
  .content
    margin: 40px 0
    text-align: center
    color: white
  .btn-dark
    background: transparent
    border: 0
    outline: 0
    margin-bottom: 20px
    font-size: 30px
  input
    max-width: 320px
    background: rgba(255, 255, 255, .05)
    color: white
    border: 1px solid rgba(255, 255, 255, 0.1)
    font-size: 11px
    padding: 4px 8px

.watch-page.container-fluid
  padding-left: 0
  padding-right: 0
.watch-player
  background: #121832
.watch-sidebar
  background: #111
  color: white
  .content
    margin: 20px
    .btn-expand
      background: transparent
      border: 0
      box-shadow: none
      outline: 0
.match-header
  .header-content
    padding: 20px
    margin: 10px 0
    color: white
  .header-sidebar
    padding: 20px
    .btn
      position: relative
      text-align: left
      font-weight: 700
      font-size: 12px
      text-transform: uppercase
      background: transparent
      padding: 3px 6px 3px 32px
      border: 1px solid #ccc
      opacity: .5
      outline: 0
      transition: all .2s
      &:hover
        opacity: 1
        color: white
        background: $danger
      i
        border-right: 1px solid #ddd
        position: absolute
        top: 0
        left: 3px
        width: 22px
        height: 24px
        display: flex
        justify-content: center
        align-items: center

a
  transition: all .3s
  &:hover
    text-decoration: none
    font-weight: bold
    color: $link

.round-info
  // margin: 10px 0
  position: relative

  p
    margin: 0

  h5
    margin: 0
  h6
    margin: 0
  .team-column
    span
      margin-right: 5px

  // .round-details
  //   font-size: 12px

  .round-badges
    position: absolute
    top: 3px
    right: 5px
    .badge
      margin-left: 5px

  .round-stats
    position: absolute
    top: 30px
    right: 5px
    p.stat
      display: inline-block
      margin-left: 10px
      font-weight: bold
      font-size: 24px
      i
        font-weight: normal
        font-size: 18px

#share
  width: 100%
  margin: 10px auto
  text-align: center
  a
    width: 50px
    height: 50px
    display: inline-block
    margin: 8px
    border-radius: 50%
    font-size: 24px
    color: #fff
    opacity: 0.75
    transition: opacity 0.15s linear
    &:hover
      opacity: 1
    i
      position: relative
      top: 50%
      transform: translateY(-50%)
  .facebook
    background: #3b5998
  .twitter
    background: #55acee
  .googleplus
    background: #dd4b39

.related-rounds
  margin-top: 20px

.comments-section
  margin-top: 20px
  .section-title
    border-bottom: 1px solid rgba(255, 255, 255, 0.15)
    margin-bottom: 0
    h5
      margin-bottom: 0
  .comments-container
    height: 260px
    overflow-y: scroll
    padding-right: 10px
    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
      background-color: rgba(0, 0, 0, 0)
    &::-webkit-scrollbar
      width: 8px
      background-color: rgba(0, 0, 0, 0)
    &::-webkit-scrollbar-thumb
      background-color: rgba(255, 255, 255, .15)

  .new-comment
    margin-top: 10px
    .post-comment
      padding-right: 10px
      display: flex
      align-items: flex-start
      margin: 10px 0
      .user-avatar
        width: 32px
        height: 32px
        min-width: 32px
        overflow: hidden
        margin: 0 5px 5px 5px
        margin-right: 15px
        img
          width: 100%
          height: auto
          border-radius: 250%
      .comment
        flex-grow: 1
        position: relative
        display: flex
        flex-wrap: wrap
        &:before
          content: ''
          height: 0
          width: 0
          position: absolute
          display: block
          border-width: 9px 7px 9px 0
          border-style: solid
          border-color: transparent rgba(255, 255, 255, .1)
          top: 3px
          left: -7px
        .comment-body
          font-size: 12px
          width: 100%

          background: rgba(255, 255, 255, .1)
          padding: 4px 8px
          border-radius: 4px
          display: flex
          textarea
            font-size: 12px
            min-height: 40px
            max-height: 40px
            height: 40px
            background-color: rgba(0, 0, 0, 0.4)
            border: 0
            color: white
            margin-left: -4px
            margin-right: 5px
            resize: none
            &::-webkit-scrollbar-track
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
              background-color: rgba(0, 0, 0, 0)
            &::-webkit-scrollbar
              width: 8px
              background-color: rgba(0, 0, 0, 0)
            &::-webkit-scrollbar-thumb
              background-color: rgba(255, 255, 255, .15)

          button
            background: rgba(0, 0, 0, 0)
            border: 0
            outline: 0
            box-shadow: none
</style>
