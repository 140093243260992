<template>
  <div class="comment-card">
    <div class="user-avatar">
      <router-link :to="{ name: 'UserSingle', params: { id: comment.user.id }}">
        <img :src="comment.user.avatar.url">
      </router-link>
    </div>
    <div class="comment">
      <div class="comment-body">{{comment.body}}</div>
      <div class="comment-footer">
        <!-- {{ $t('UTILITY.by') }} -->
        <router-link :to="{ name: 'UserSingle', params: { id: comment.user.id }}">
          <span>{{comment.user.display_name}}</span>
        </router-link>
        <!-- {{ $t('UTILITY.at') }}  -->
        {{comment.created_at_friendly}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Comment",
  props: {
    comment: Object,
  },
  methods: {
    
  },
  components: {
    
  }
};
</script>

<style lang="sass" scoped>
.comment-card
  display: flex
  align-items: flex-start
  margin: 10px 0

  .user-avatar
    width: 32px
    height: 32px
    min-width: 32px
    overflow: hidden
    // padding: 5px
    margin: 0 5px 5px 5px
    margin-right: 15px
    img
      width: 100%
      height: auto
      border-radius: 250%
  .comment
    flex-grow: 1
    position: relative
    display: flex
    flex-wrap: wrap
    &:before
      content: ''
      height: 0
      width: 0
      position: absolute
      display: block
      border-width: 9px 7px 9px 0
      border-style: solid
      border-color: transparent rgba(255, 255, 255, .1)
      top: 3px
      left: -7px
    .comment-body
      font-size: 12px
      width: 100%

      background: rgba(255, 255, 255, .1)
      padding: 4px 8px
      border-radius: 4px
    .user-details
      span
        font-weight: bold
    .comment-footer
      font-size: 11px
      width: 100%
      a
        font-weight: bold
        color: rgba(255, 255, 255, .6)
        cursor: pointer
        &:hover
          color: $danger
</style>
