<template>
  <div class="rounds-bar-horizontal">
    <div class="legend-round">
      <div class="line">T</div>
      <div class="number">round</div>
      <div class="line">CT</div>
    </div>
    <div
      :class="`round-column round-${this_round.round_number} ${this_round.winner} ${this_round.reason} ${this_round.round_number == 16 ? 'swap' : ''}`"
      v-for="this_round in rounds"
      :key="this_round.id"
    >
      <div class="swap-round" v-if="this_round.round_number == 16">
        <div class="line">{{this_round.team_b_score}}</div>
        <div class="number">
          <SvgIcon icon="swap"></SvgIcon>
        </div>
        <div class="line">{{this_round.team_a_score}}</div>
      </div>
      <!-- <router-link :to="{ name: 'RoundSingle', params: { id: this_round.id} }" class="round-cont"> -->
      <a @click.stop.prevent="loadFullRound(this_round.id)" href="#" 
        :class="[round.round_number == this_round.round_number ? 'active' : '', 'round-cont']">
        <div class="line terrorists">
          <span v-if="this_round.reason == 'reason_terrorist_win'">
            <SvgIcon icon="dead" color="#FF867F"></SvgIcon>
          </span>
          <span v-if="this_round.reason == 'reason_target_bombed'">
            <SvgIcon icon="explosion" color="#FF867F"></SvgIcon>
          </span>
        </div>
        <div class="number">{{this_round.round_number}}</div>
        <div class="line cts">
          <span v-if="this_round.reason == 'reason_ct_win'">
            <SvgIcon icon="dead" color="#5CBBFF"></SvgIcon>
          </span>
          <span v-if="this_round.reason == 'reason_target_saved'">
            <SvgIcon icon="time" color="#5CBBFF"></SvgIcon>
          </span>
          <span v-if="this_round.reason == 'reason_bomb_defused'">
            <SvgIcon icon="ct_win" color="#5CBBFF"></SvgIcon>
          </span>
        </div>
      </a>
          
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
export default {
  name: "RoundsBar",
  props: {
    rounds: Array,
    round: Object,
  },
  methods: {
    loadFullRound(round_id) {
      this.$emit('loadFullRound', round_id);
    },
  },
  components: {
    SvgIcon
  }
};
</script>

<style lang="sass" scoped>
.rounds-bar-horizontal
  width: 100%
  display: flex
  padding: 0
  justify-content: center
  flex-wrap: wrap

  margin: 0
  background: #f1f1f1

  .legend-round
    padding: 0
    text-align: center
    font-weight: bold
    margin-right: 4px
    opacity: .4
    .line
      height: 20px
      font-size: 12px
      color: #5CBBFF
      &:first-of-type
        color: #FF867F
    .number
      color: #444
      font-size: 12px

  .round-column
    // background: rgba(255, 255, 255, .4)
    .round-cont
      width: 22px
      margin-right: 0px
      text-align: center
      display: inline-block
      transition: all .1s linear
      padding: 2px 0
      .svg-icon
        opacity: .4
        overflow: hidden
      .line
        height: 20px
      .number
        text-align: center
        font-weight: bold
        color: #999
        font-size: 12px
        transition: all .1s linear
      
      &.active
        background: #121832
        .number
          color: white

      &:hover
        background: #121832
        text-decoration: none
        .number
          color: white
      
      // &:hover
      //   background: #fcfcfc
      //   box-shadow: 0 0 0 1px #dedede
      //   transform: scale(1.1) translateY(-3px)
      //   text-decoration: none
      //   padding: 3px 2px
      //   svg
      //     opacity: 1
      //   .number
      //     color: #111

    &.swap
      width: 54px
      display: flex
      height: 52px
      .swap-round
        display: inline-block
        text-align: center
        margin: 0 8px
        color: #333
        opacity: .8
        transform: translateY(-5px)
        margin-top: 8px
        .line
          font-size: 12px
          &:first-of-type
            margin-bottom: -4px
      .round-cont
        height: 62px
</style>
