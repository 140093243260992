<template>
  <div class="svg-icon">
    <svg
      width="16"
      height="16"
      fill="none"
      id="resource-dead.svg"
      name="dead"
      :color="color"
      v-if="icon == 'dead'"
    >
      <path
        d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
        fill="currentColor"
      ></path>
    </svg>

    <svg
      width="16"
      height="16"
      fill="none"
      id="resource-explosion.svg"
      name="explosion"
      :color="color"
      v-if="icon == 'explosion'"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 13L0 9h4.5L1.684 3.25 6.5 6l2-6 2 6 5.079-2.344L12.5 9H16l-6.5 4 2-2.5H10L11.5 8l-2 1-1-3-1 3-2-1.5 1.5 3H5L7.5 13zm5.032-11.54L13.86.24l-.444 1.822-1.238.855.355-1.457zM4.07 0l.332 2.478 1.401 1.427.019-2.121L4.07 0zM1.49 7.51L.193 6.258l1.844.337.927 1.187-1.475-.27z"
        fill="currentColor"
      ></path>
    </svg>

    <svg
      width="16"
      height="16"
      fill="none"
      id="resource-time.svg"
      name="time"
      :color="color"
      v-if="icon == 'time'"
    >
      <mask id="time__a" fill="#fff">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
        ></path>
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
        fill="currentColor"
      ></path>
      <path
        d="M8.429 8.857v1.5h.301l.278-.116-.58-1.384zm.305-.128l.58 1.384.276-.116.21-.213-1.066-1.055zm.03-.033l-1.171-.938-.002.003 1.172.935zM8 8v1.5h1.5V8H8zm0 6.214A6.214 6.214 0 0 0 14.214 8h-3A3.214 3.214 0 0 1 8 11.214v3zM1.786 8A6.214 6.214 0 0 0 8 14.214v-3A3.214 3.214 0 0 1 4.786 8h-3zM8 1.786A6.214 6.214 0 0 0 1.786 8h3A3.214 3.214 0 0 1 8 4.786v-3zM14.214 8A6.214 6.214 0 0 0 8 1.786v3A3.214 3.214 0 0 1 11.214 8h3zM8 15.5A7.5 7.5 0 0 0 15.5 8h-3A4.5 4.5 0 0 1 8 12.5v3zM.5 8A7.5 7.5 0 0 0 8 15.5v-3A4.5 4.5 0 0 1 3.5 8h-3zM8 .5A7.5 7.5 0 0 0 .5 8h3A4.5 4.5 0 0 1 8 3.5v-3zM15.5 8A7.5 7.5 0 0 0 8 .5v3A4.5 4.5 0 0 1 12.5 8h3zm-7.071-.643H6.286v3h2.143v-3zm-.274-.011l-.306.128 1.16 2.767.305-.128-1.16-2.767zm-.564.415c.024-.03.05-.06.076-.086l2.134 2.109c.047-.048.092-.099.135-.152L7.59 7.76zm-.234.668c0-.252.089-.488.236-.67l2.34 1.876c.265-.33.424-.752.424-1.206h-3zm0-2.572V8.43h3V5.857h-3zM8.43 6.93c-.592 0-1.072-.48-1.072-1.072h3A1.929 1.929 0 0 0 8.43 3.93v3zM9.5 5.857c0 .592-.48 1.072-1.071 1.072v-3A1.929 1.929 0 0 0 6.5 5.857h3zM9.5 8V5.857h-3V8h3zM6.286 9.5H8v-3H6.286v3zm1.071-1.072c0 .593-.48 1.072-1.071 1.072v-3a1.929 1.929 0 0 0-1.929 1.928h3zm-1.071-1.07c.592 0 1.071.48 1.071 1.07h-3c0 1.065.863 1.93 1.929 1.93v-3z"
        fill="currentColor"
        mask="url(#time__a)"
      ></path>
    </svg>

    <svg
      width="14"
      height="14"
      fill="none"
      id="resource-ct_win.svg"
      name="ct_win"
      :color="color"
      v-if="icon == 'ct_win'"
    >
      <path
        d="M5.084 1.147C3.826-.438 1.938-.042 1.151.355l.974 1.065c.393 0 .993.124 1.386.917.364.733 0 1.585-.393 2.378-.113.228.472.635.786 1.586 1.124 3.4 3.146 5.153 3.54 5.946.393.793-.348 1.602.786 1.586.845-.012.863-2.034-.423-3.535-2.131-2.486-2.33-4.79-2.33-5.583 0-.793.787-1.982-.393-3.568z"
        fill="currentColor"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.847 3.551a1.43 1.43 0 0 1-.485-.124c-.805-.369-.947-.964-.959-1.357L.307 1.13c-.371.798-.709 2.698.915 3.906.565.393 1.074.55 1.531.586L2.738 5.6c-.068-.098-.16-.23-.22-.349-.06-.115-.228-.466-.037-.851.153-.31.282-.59.366-.849zm.762-.095c-.09.419-.287.847-.491 1.26-.053.106.048.253.203.477.074.108.16.233.248.38a6.54 6.54 0 0 0-.249-.38c-.155-.224-.256-.37-.203-.478.205-.412.401-.84.492-1.26zM6.24 5.443c1.096.208 2.607.737 4.213 2.03 1.54 1.238 3.562 1.158 3.547.312-.012-.7-.317-.676-.738-.641-.26.02-.564.045-.872-.095-.225-.103-.53-.332-.926-.63-1.016-.761-2.638-1.977-5.088-2.711-.03.175-.066.334-.097.47l-.005.023c-.06.27-.087.41-.087.514 0 .163.01.412.053.728z"
        fill="currentColor"
      ></path>
    </svg>

    <svg v-if="icon == 'swap'" width="16" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
          <path d="M13.1 2.9l-2.9 2.9h2.2c0 2.4-2 4.4-4.4 4.4-.7 0-1.4-.2-2-.5l-1.1 1.1c.9.6 2 .9 3.1.9 3.2 0 5.8-2.6 5.8-5.8H16l-2.9-3z" fill="#CBB872" />
          <path d="M3.6 5.8c0-2.4 2-4.4 4.4-4.4.7 0 1.4.2 2 .5L11.1.8C10.2.3 9.1 0 8 0 4.8 0 2.2 2.6 2.2 5.8H0l2.9 2.9 2.9-2.9H3.6z" fill="#75A3D9" />
      </g>
      <defs>
          <clipPath id="clip0">
              <path fill="#fff" d="M0 0h16v11.6H0z" />
          </clipPath>
      </defs>
  </svg>
  </div>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: String,
    color: String,
  },
};
</script>

<style lang="sass" scoped>

</style>
