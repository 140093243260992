<template>
  <table
    :class="`table table-scoreboard is-hoverable table-sm ${
      teamClass || ''
    } mb-0`"
  >
    <thead>
      <tr>
        <th>
          <h4 class="mb-0">
            <small>
              {{ score }}
            </small>
            <router-link
              :to="{ name: 'TeamSingle', params: { id: team.to_param } }"
              v-if="team"
              >{{ team.name }}</router-link
            >
            <span v-else>{{ teamClass || "" }}</span>
          </h4>
        </th>
        <th><abbr title="inimigos mortos">Kills</abbr></th>
        <th><abbr title="assistencias">Assist</abbr></th>
        <th><abbr title="vezes que você morreu">Deaths</abbr></th>
        <th><abbr title="inimigos mortos por round">KDR</abbr></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="stat in stats" :key="stat.steamId">
        <td>
          <div v-if="stat.player">{{ stat.player.nickname }}</div>
          <div v-else>{{ stat.steamId }}</div>
        </td>
        <td>{{ stat.kills }}</td>
        <td>{{ stat.assists }}</td>
        <td>{{ stat.deaths }}</td>
        <td>{{ (stat.kills / stat.deaths).toFixed(2) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ScoreboardTable",
  props: {
    teamClass: String,
    score: Number,
    stats: Array,
    team: Object,
  },
  methods: {},
  components: {},
};
</script>

<style lang="sass" scoped>
.table-scoreboard
  td:first-child
    width: 70%

  .team-column
    display: flex
    // justify-content: space-around
    div
      // font-size: 18px
      font-weight: bold
      &:first-child
        margin-right: 5px
      &:last-child
        margin-left: 5px
</style>
